import React, { useRef } from "react";
import "./styles.css";
import HeroBox from "../HeroBox";
import { heroImages } from "../../resources/images";
// import FoodApplication from "../../resources/files/aba-food-vendor-application-2024.pdf";

const Vendors = () => {
  const myRef = useRef(null);
  const executeScroll = () => myRef.current.scrollIntoView();
  return (
    <div>
      <HeroBox
        bgImage={heroImages.vendors}
        title={"Vendors"}
        content={"All 2024 vendor spaces have been filled!"}
        executeScroll={executeScroll}
      />

      <section className="top-section" ref={myRef}>
        <section
          className="section-description vendors js--section-intro"
          id="vendors-top"
        >
          <div>
            <h1>Become a Vendor at Angola Balloons Aloft 2025!</h1>
          </div>
          <div>
            <div className="vendorInfo">
              <p>Contact Jim Owen for further details.</p>
              <p>
                Call: <a href="tel:260-348-4500">(260) 348 - 4500</a>
                <br />
                Email:{" "}
                <a href="mailto:wjamesowen@msn.com">wjamesowen@msn.com</a>
              </p>
            </div>
          </div>
          <div className="row vendorButtons">
            <div>
              {/* <a
                href={FoodApplication}
                target="_blank"
                rel="noreferrer"
                className="btn btn-full"
              >
                Food Vendor Application
              </a> */}
            </div>
            <div>
              {/* <a href="resources/files/aba_retail_vendor_application_2019_final.pdf" className="btn btn-full">Retail Vendor Application</a> */}
            </div>
          </div>
        </section>
      </section>
    </div>
  );
};

export default Vendors;
