import React from 'react';
import { Link } from 'react-router-dom';
import './styles.css';
import NotFoundImage from './404notfound.svg';

function NotFound() {
    return (
        <div className="not-found__wrapper">
            <h1 className="not-found__header">Oops! We can't seem to find that page!</h1>
            <img src={NotFoundImage} alt="Page Not Found" className="not-found__image"/>
            <div className="not-found__text">
                <p>We can't seem to find the page you're looking for.</p>
                <p>Click here to go back <Link to="/">Home</Link>.</p>
            </div>
        </div>
    )
}

export default NotFound;