import React from 'react';
import './styles.css'

const HeroBox = ({ bgImage, title, content, executeScroll, buttonText='Learn More'}) => {
    return (
        <div 
            className='hero-text-box'
            style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url(${bgImage})` }}
        >
            <div>
            <h1>{title}</h1>
            {/* <h2>{subtitle}</h2> */}
            <p>{content}</p>
            <button onClick={executeScroll} className="learn-more-btn">{buttonText}</button>
            </div>
            <div></div>
        </div>
    );
};

export default HeroBox;