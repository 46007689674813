import React, { useRef } from "react";
import "./styles.css";
import HeroBox from "../HeroBox";
import { heroImages } from "../../resources/images";

const Partner = () => {
  const myRef = useRef(null);
  const executeScroll = () => myRef.current.scrollIntoView();
  return (
    <div>
      <HeroBox
        bgImage={heroImages.partner}
        title={"Partner with us"}
        content={"Learn about sponsorship opportunities"}
        executeScroll={executeScroll}
      />

      <section
        className="section-description partners js--section-intro"
        id="partner-top"
      >
        <h1>Angola Balloons Aloft Sponsorship</h1>
        <p>
          The success of Angola Balloons Aloft is truly a product of the
          generous giving from companies and individuals that see the value in
          this FREE and exciting family-oriented event. As the many committee
          members, volunteers and community organizers come together, an
          outstanding weekend event is produced. The following lists the various
          sponsorship categories and advertising criteria. Some sponsorship
          levels are limited and are sold on a first come basis. Please consider
          being a supporter of this wonderful and exciting weekend event!
        </p>
        <div>
          <div>
            <h2>
              <i className="fas fa-award vendorIcon"></i>
              Sponsorship Amenities
            </h2>
            <p>
              The Angola Balloons Aloft Event Committee is preparing an
              extensive marketing campaign in preparation for the Angola
              Balloons Aloft hot air balloon festival. Your sponsorship will be
              recognized in the following ways:
            </p>
            <ul>
              <li>
                Your company’s name and logo exposed to 75,000+ potential
                consumers through advertising in print, radio and web activity.
              </li>
              <li>
                Create awareness to potential consumers about your products and
                services.
              </li>
              <li>Strengthen your perception as a community supporter.</li>
              <li>
                Increase sales, branding, distribution and networking
                opportunities
              </li>
            </ul>
          </div>
          <div>
            <h2>
              <i className="fas fa-hand-paper vendorIcon"></i>
              Our Advertising Campaign
            </h2>
            <ul>
              <li>Media releases through video, print, website, and radio.</li>
              <li>Distribution of posters with elite sponsors listed.</li>
              <li>
                Listing on the Angola Balloons Aloft website and Facebook for a
                full year with links to your company website for all sponsors.
              </li>
              <li>
                Promotion for all sponsors at the event with banners, signs and
                extensive commentary recognition during announcements.
              </li>
              <li>
                Certificates of appreciation for balloon and elite sponsors
                representing your community spirit to your patrons, customers,
                and staff.
              </li>
            </ul>
          </div>
        </div>
        <div>
          <h2 className="sponsor-levels-title">Available Sponsorships</h2>
        </div>
        <div className="sponsor-cards-wrapper">
          <div className="sponsor-card">
            <h2 className="sponsor-title">Gold Sponsor</h2>
            <p className="sponsor-price">$10,000</p>
            <ul className="sponsor-list">
              <li className="sponsor-list-item">
                <i className="fas fa-check"></i>Logo will appear on ALL
                advertising indicating Gold Sponsor status
              </li>
              <li className="sponsor-list-item">
                <i className="fas fa-check"></i>Balloon basket banner /w logo on
                your dedicated balloon (your logo will additionally appear as
                advertising on all basket banners including competition balloons
                and special shapes)
              </li>
              <li className="sponsor-list-item">
                <i className="fas fa-check"></i>Facebook advertising for a full
                year with links to your company website
              </li>
              <li className="sponsor-list-item">
                <i className="fas fa-check"></i>On-field banners listing Gold
                Sponsors only
              </li>
              <li className="sponsor-list-item">
                <i className="fas fa-check"></i>Web, radio and newspaper
                advertising
              </li>
              <li className="sponsor-list-item">
                <i className="fas fa-check"></i>Periodic field commentary during
                event indicating your Gold Level Sponsorship
              </li>
            </ul>
          </div>
          <div className="sponsor-card">
            <h2 className="sponsor-title">VIP Sponsor</h2>
            <p className="sponsor-price">$2,500</p>
            <ul className="sponsor-list">
              <li className="sponsor-list-item">
                <i className="fas fa-check"></i>Balloon basket banner /w logo on
                your dedicated balloon
              </li>
              <li className="sponsor-list-item">
                <i className="fas fa-check"></i>Facebook advertising for a full
                year with links to your company website
              </li>
              <li className="sponsor-list-item">
                <i className="fas fa-check"></i>On-field banners
              </li>
              <li className="sponsor-list-item">
                <i className="fas fa-check"></i>Web, radio and newspaper
                advertising
              </li>
              <li className="sponsor-list-item">
                <i className="fas fa-check"></i>Periodic field commentary during
                event highlighting your VIP Sponsorship
              </li>
            </ul>
          </div>
          <div className="sponsor-card">
            <h2 className="sponsor-title">Illumination Sponsor</h2>
            <p className="sponsor-price">$1,000</p>
            <ul className="sponsor-list">
              <li className="sponsor-list-item">
                <i className="fas fa-check"></i>Facebook advertising for a full
                year with links to your company website
              </li>
              <li className="sponsor-list-item">
                <i className="fas fa-check"></i>On-field banners
              </li>
              <li className="sponsor-list-item">
                <i className="fas fa-check"></i>Web, radio and newspaper
                advertising
              </li>
              <li className="sponsor-list-item">
                <i className="fas fa-check"></i>Periodic field commentary during
                event indicating your level of Sponsorship and highlighting the
                illumination sponsors solely during the illumination
              </li>
            </ul>
          </div>
          <div className="sponsor-card">
            <h2 className="sponsor-title">Balloon Sponsor</h2>
            <p className="sponsor-price">$750</p>
            <ul className="sponsor-list">
              <li className="sponsor-list-item">
                <i className="fas fa-check"></i>Balloon basket banner /w logo on
                your dedicated balloon
              </li>
              <li className="sponsor-list-item">
                <i className="fas fa-check"></i>Facebook advertising for a full
                year with links to your company website
              </li>
              <li className="sponsor-list-item">
                <i className="fas fa-check"></i>On-field banners
              </li>
              <li className="sponsor-list-item">
                <i className="fas fa-check"></i>Web, radio and newspaper
                advertising
              </li>
              <li className="sponsor-list-item">
                <i className="fas fa-check"></i>Periodic field commentary during
                event indicating your level of Sponsorship
              </li>
            </ul>
          </div>
          <div className="sponsor-card">
            <h2 className="sponsor-title">Kid&#39;s Fun Zone Sponsor</h2>
            <p className="sponsor-price">$500</p>
            <ul className="sponsor-list">
              <li className="sponsor-list-item">
                <i className="fas fa-check"></i>Name &amp; logo on Kid&#39;s Fun
                Zone banners displayed in the Fun Zone Area
              </li>
              <li className="sponsor-list-item">
                <i className="fas fa-check"></i>Facebook advertising for a full
                year with links to your company website
              </li>
              <li className="sponsor-list-item">
                <i className="fas fa-check"></i>Web, radio and newspaper
                advertising
              </li>
              <li className="sponsor-list-item">
                <i className="fas fa-check"></i>Periodic field commentary during
                event indicating your level of Sponsorship
              </li>
            </ul>
          </div>
          <div className="sponsor-card">
            <h2 className="sponsor-title">T-Shirt Sponsor</h2>
            <p className="sponsor-price">$500</p>
            <ul className="sponsor-list">
              <li className="sponsor-list-item">
                <i className="fas fa-check"></i>Logo appears on back of Angola
                Balloons Aloft T-Shirts for both volunteer and shirts for
                purchase
              </li>
              <li className="sponsor-list-item">
                <i className="fas fa-check"></i>Facebook advertising for a full
                year with links to your company website
              </li>
              <li className="sponsor-list-item">
                <i className="fas fa-check"></i>On-field banners
              </li>
              <li className="sponsor-list-item">
                <i className="fas fa-check"></i>Web, radio and newspaper
                advertising
              </li>
            </ul>
          </div>
        </div>
        <p>
          Thank you for considering to be a part of Angola&#39;s spectacular hot
          air balloon weekend! For more information or questions contact Tim
          Crooks, Angola Balloons Aloft, Director at{" "}
          <a href="mailto:crookstim@outlook.com">crookstim@outlook.com</a> or
          call at <a href="tel:2603168010">(260) 316-8010</a>.
        </p>
      </section>
    </div>
  );
};

export default Partner;
