export const logo = "https://i1.lensdump.com/i/gdGWT7.png";

export const heroImages = {
  home: "https://i2.lensdump.com/i/k6rma7.webp",
  sponsors: "https://i2.lensdump.com/i/k6r4lF.webp",
  pilots: "https://i1.lensdump.com/i/k61r5i.webp",
  partner: "https://i1.lensdump.com/i/k6EvXr.webp",
  events: "https://i3.lensdump.com/i/k6rKb3.webp",
  schedule: "https://i3.lensdump.com/i/k6rHPq.webp",
  special_shapes: "https://i3.lensdump.com/i/k6rHPq.webp",
  vendors: "https://i1.lensdump.com/i/k6T5nT.webp",
  pilot_registration: "https://i.lensdump.com/i/gdbZXH.jpg",
};

export const homeFullWidthGallery = [
  {
    image: "https://i1.lensdump.com/i/gdGnvz.jpg",
    alt: "Angola Balloons Aloft Balloons",
  },
  {
    image: "https://i.lensdump.com/i/gdGSWC.jpg",
    alt: "Angola Balloons Aloft Balloons",
  },
  {
    image: "https://i3.lensdump.com/i/gdGH32.jpg",
    alt: "Angola Balloons Aloft Balloons",
  },
  {
    image: "https://i2.lensdump.com/i/gdGOg9.jpg",
    alt: "Angola Balloons Aloft Balloons",
  },
];

export const pilots = [
  {
    pilotName: "Al Smith",
    location: "Grass Lake, MI",
    balloonName: "Tom Cat",
    image: "https://a.l3n.co/i/OikqtT.webp",
    sponsor: "",
  },
  {
    pilotName: "Allen Anderson",
    location: "Minden, NV",
    balloonName: "Pixie Styx",
    image: "https://a.l3n.co/i/OikGCv.webp",
    sponsor: "",
  },
  {
    pilotName: "Andrew Barnum",
    location: "Ottawa Hills, OH",
    balloonName: "Jabberwocky",
    image: "https://c.l3n.co/i/Oi6Wfb.webp",
    sponsor: "",
  },
  {
    pilotName: "Andrew Brindley",
    location: "Indianapolis, IN",
    balloonName: "Ellie the Elephant",
    image: "https://b.l3n.co/i/OikLje.webp",
    sponsor: "",
  },
  {
    pilotName: "Ben Leatherman",
    location: "East Leroy, MI",
    balloonName: "Borrowed Time",
    image: "https://a.l3n.co/i/za98R9.webp",
    sponsor: "",
  },
  {
    pilotName: "Bill Sterner",
    location: "Oxford, MI",
    balloonName: "Godspeed",
    image: "https://b.l3n.co/i/Oik522.webp",
    sponsor: "",
  },
  {
    pilotName: "Brad Burdue",
    location: "Whitehouse. OH",
    balloonName: "Tooty Frooty",
    image: "https://c.l3n.co/i/OikPwK.webp",
    sponsor: "",
  },
  {
    pilotName: "Chad Crabtree",
    location: "Delton, MI",
    balloonName: "Heidi's Star",
    image: "https://a.l3n.co/i/OikStM.webp",
    sponsor: "",
  },

  {
    pilotName: "Chris Smart",
    location: "Roanoake, IN",
    balloonName: "ReMax",
    image: "https://c.l3n.co/i/Oikph0.webp",
    sponsor: "",
  },
  {
    pilotName: "Chuck Walz",
    location: "Munith, MI",
    balloonName: "Yellow Bird",
    image: "https://c.l3n.co/i/OikYMx.webp",
    sponsor: "",
  },

  {
    pilotName: "Darrin Fall",
    location: "Grass Lake, MI",
    balloonName: "Turquoise Noise",
    image: "https://b.l3n.co/i/Oi6ApT.webp",
    sponsor: "",
  },
  {
    pilotName: "David Seekell",
    location: "Jackson, MI",
    balloonName: "Shining Light",
    image: "https://a.l3n.co/i/OikQ6C.webp",
    sponsor: "",
  },

  {
    pilotName: "Dennis Hall",
    location: "Howell, MI",
    balloonName: "Michigandair",
    image: "https://b.l3n.co/i/OikB4b.webp",
    sponsor: "",
  },
  {
    pilotName: "Derrick Jones",
    location: "Grandville, MI",
    balloonName: "Puddy Cat",
    image: "https://c.l3n.co/i/Oik9bz.webp",
    sponsor: "",
  },
  {
    pilotName: "Elisabeth McClurg",
    location: "Ashland, OH",
    balloonName: "Shenanigans!",
    image: "https://a.l3n.co/i/OikF1Z.webp",
    sponsor: "",
  },
  {
    pilotName: "Jack Semler",
    location: "Westfield, IN",
    balloonName: "Smiley Scarecrow",
    image: "https://c.l3n.co/i/OikHzq.webp",
    sponsor: "",
  },
  {
    pilotName: "Janet Lutkus",
    location: "Medina, OH",
    balloonName: "Goodnight Gracie",
    image: "https://c.l3n.co/i/Oik4dF.webp",
    sponsor: "",
  },
  {
    pilotName: "Joey Strutz",
    location: "Brighton, MI",
    balloonName: "Patches O'Houlihan",
    image: "https://b.l3n.co/i/OikVl5.webp",
    sponsor: "",
  },
  {
    pilotName: "Jordan Cox",
    location: "Carmel, IN",
    balloonName: "Tallulah",
    image: "https://b.l3n.co/i/HJo40P.webp",
    sponsor: "",
  },
  {
    pilotName: "Keith Kohtz",
    location: "Wayland, MI",
    balloonName: "Terry Mouse",
    image: "https://a.l3n.co/i/OikNzc.webp",
    sponsor: "",
  },
  {
    pilotName: "Landon Kohtz",
    location: "Wayland, MI",
    balloonName: "Overblown",
    image: "https://b.l3n.co/i/Oikb0i.webp",
    sponsor: "",
  },
  {
    pilotName: "Larry Coan",
    location: "New Hudson, MI",
    balloonName: "Dark Horse",
    image: "https://a.l3n.co/i/Oi6j07.webp",
    sponsor: "",
  },
  {
    pilotName: "Lori Nowak",
    location: "Xenia, OH",
    balloonName: "Kevin",
    image: "https://b.l3n.co/i/OikeKQ.webp",
    sponsor: "",
  },
  {
    pilotName: "Michael O'Brien",
    location: "Berkley, MI",
    balloonName: "Coulda Been a Boat",
    image: "https://c.l3n.co/i/Oikcfm.webp",
    sponsor: "",
  },
  {
    pilotName: "Pat Rolfe",
    location: "Battle Creek, MI",
    balloonName: "Cynthia Seal",
    image: "https://a.l3n.co/i/Oikwqo.webp",
    sponsor: "",
  },
  {
    pilotName: "Paul Petrehn",
    location: "Howell, MI",
    balloonName: "Enigma",
    image: "https://b.l3n.co/i/OikDC1.webp",
    sponsor: "",
  },
  {
    pilotName: "Phil Clinger",
    location: "Battle Creek, MI",
    balloonName: "Liberty",
    image: "https://b.l3n.co/i/Oikhw5.webp",
    sponsor: "",
  },
  {
    pilotName: "Rick Kerber",
    location: "Wayland, MI",
    balloonName: "Michigan Cat",
    image: "https://c.l3n.co/i/OiknPa.webp",
    sponsor: "",
  },
  {
    pilotName: "Ryan Duff",
    location: "Beachwood, OH",
    balloonName: "Purple Haze",
    image: "https://a.l3n.co/i/OikohH.webp",
    sponsor: "",
  },
  {
    pilotName: "Shawn Raya",
    location: "Highland, MI",
    balloonName: "Sullair, A Hitachi Group Company",
    image: "https://b.l3n.co/i/OiklwA.webp",
    sponsor: "",
  },
  {
    pilotName: "Steve Mitchell",
    location: "Chagrin Falls, OH",
    balloonName: "Old Cat",
    image: "https://b.l3n.co/i/OikO6D.webp",
    sponsor: "",
  },
  {
    pilotName: "Steve Sitko",
    location: "Jackon, MI",
    balloonName: "Maveryx",
    image: "https://c.l3n.co/i/Oi681z.webp",
    sponsor: "",
  },
  {
    pilotName: "Steve Wilkinson",
    location: "Palm Desert, CA",
    balloonName: "Cosmic Crisp",
    image: "https://a.l3n.co/i/Oik3jr.webp",
    sponsor: "",
  },
];
