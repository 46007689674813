import React from "react";
import "./styles.css";

const PilotCard = ({
  //   pilot: { image, balloonName, pilotName, location, sponsor },
  pilot: { image, balloonName, pilotName, location },
}) => {
  return (
    <div className="pilot-card">
      <img src={image} alt={balloonName} className="pilot-card--image"></img>
      <div className="pilot-card--text">
        <h3 className="pilot-card--balloon-name">{balloonName}</h3>
        <h4 className="pilot-card--pilot-name">{pilotName}</h4>
        <h4 className="pilot-card--location">{location}</h4>
        {/* <h4 className="pilot-card--sponsor"><span className="italic">Sponsor:</span> {sponsor}</h4> */}
      </div>
    </div>
  );
};

export default PilotCard;
