import React from 'react';
import { Route, HashRouter, Switch } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import Home from './Home';
import Sponsors from './Sponsors';
// import Events from './Events';
import Partner from './Partner';
import Pilots from './Pilots';
import Schedule from './Schedule';
import Vendors from './Vendors';
import PilotRegistration from './PilotRegistration';
import Maintenance from './Maintenance';
import NotFound from './404NotFound';
import '../styles/App.css';
import ScrollToTop from './ScrollToTop';
import SpecialShapes from './Special_Shapes';

class App extends React.Component {
    render() {
        return (
            <div className="root">
                <HashRouter basename="/">
                    <ScrollToTop />
                    <Header />
                    <div className="content">
                        <Switch>
                            <Route  path="/" exact component={Home}/>
                            <Route  path="/sponsors" component={Sponsors}/>
                            <Route  path="/events" component={Maintenance}/>
                            <Route  path="/partner" component={Partner}/>
                            <Route  path="/pilots" component={Pilots}/>
                            <Route  path="/schedule" component={Schedule}/>
                            <Route  path="/special_shapes" component={SpecialShapes}/>
                            <Route  path="/vendors" component={Vendors}/>
                            <Route  path="/pilot_registration" component={PilotRegistration}/>
                            <Route component={NotFound} />
                        </Switch>
                    </div>
                    <Footer />
                </HashRouter>
            </div>
        );
    };
};

export default App;