import React from 'react';
import { Link } from 'react-router-dom';
import { footerColumns } from '../../resources/footer-links';
import {logo} from '../../resources/images';
import './styles.css';


const Footer = () => {
    const date = new Date();

    const renderFooterColumns = (columns) => {
        return columns.map(column => {
            return (
                <div className="footer--column" key={column.title}>
                    <h3>{column.title}</h3>
                    <ul>
                        {column.items.map((item) => {
                            if (item.redirect === false) {
                                return <li key={item.title}><Link className="footer--column--link" to={item.url}>{item.title}</Link></li>
                            } else if (item.redirect === true) {
                                return <li key={item.title}><a className="footer--column--link" href={item.url} target="_blank" rel="noreferrer" >{item.title}</a></li>
                            } else {
                                return false;
                            }
                        })}
                    </ul>
                </div>
            );
        });
    }

    return (
        <footer>
            <div className="footer--logo">
                <div className="footer--logo--col1">
                    <h3><Link className="footer--logo--links" to="/"><img src={logo} alt="Logo" /></Link></h3>
                </div>
                <div className="footer--logo--col1 footer--contact">
                    <h3>Contact</h3>
                    <p><a href="mailto:angolaballoonsaloft@gmail.com">angolaballoonsaloft@gmail.com</a></p>
                    <p><a href="tel:2606655386">260-665-5386</a></p>
                </div>
            </div>
            <div className="footer--columns">
                {renderFooterColumns(footerColumns)}
            </div>
            <div className="footer--legal">
                <span>Copyright &#169; {date.getFullYear()} by Angola Balloons Aloft.&nbsp;</span><span>All Rights Reserved.</span>
            </div>
        </footer>
    );
};

export default Footer;