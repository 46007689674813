export const footerColumns = [
    {
      title: 'Links',
      items: [
        {
          title: 'Home',
          url: '/',
          redirect: false
        },
        {
          title: 'Sponsors',
          url: '/sponsors',
          redirect: false
        },
        {
          title: 'Pilots',
          url: '/pilots',
          redirect: false
        },
        {
          title: 'Partner',
          url: '/partner',
          redirect: false
        },
        // {
        //   title: 'Events',
        //   url: '/events',
        //   redirect: false
        // },
        {
          title: 'Schedule',
          url: '/schedule',
          redirect: false
        },
        {
          title: 'Special Shapes',
          url: '/special_shapes',
          redirect: false
        },
        {
          title: 'Vendors',
          url: '/vendors',
          redirect: false
        },
        // {
        //   title: 'Pilot Registration',
        //   url: '/pilot_registration',
        //   redirect: false
        // },
      ]
    },
    {
      title: 'Social',
      items: [
        {
          title: 'Facebook',
          url: 'https://www.facebook.com/angolaballoonsaloft/',
          redirect: true
        },
        {
          title: 'Twitter',
          url: 'https://twitter.com/BalloonsAloft1',
          redirect: true
        }
      ]
    }
  ]