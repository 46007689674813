import React from 'react';
import './styles.css';
import ConstructionImage from './construction.svg';

function Maintenance() {
    return (
        <div className="maintenance__wrapper">
            <h1 className="maintenance__header">Page Under Construction</h1>
            <img src={ConstructionImage} alt="Site under construction" className="maintenance__image"/>
            <div className="maintenance__text">
                <p>We're working hard to get this page set up!</p>
                <p>Please check back soon for new details.</p>
            </div>
        </div>
    )
}

export default Maintenance;