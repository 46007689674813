export const sponsors = {
  gold: [
    {
      name: "Cameron Memorial Hospital",
      image: "https://i1.lensdump.com/i/gdadtr.jpg",
      url: "https://www.cameronmch.com/",
    },
    {
      name: "Steuben County Tourism Bureau",
      image: "https://i3.lensdump.com/i/gdaBP3.jpg",
      url: "https://visitsteubencounty.com/",
    },
    {
      name: "City of Angola",
      image: "https://i.lensdump.com/i/gdaVb7.png",
      url: "https://www.angolain.org/",
    },
    {
      name: "Steuben County Community Foundation",
      image: "https://i2.lensdump.com/i/gdaq4F.jpg",
      url: "https://steubenfoundation.org/",
    },
    {
      name: "Trine University",
      image: "https://i3.lensdump.com/i/gdaOzQ.gif",
      url: "https://www.trine.edu/",
    },
    {
      name: "Miller Poultry",
      image: "https://i2.lensdump.com/i/tHXmOF.png",
      url: "https://millerpoultry.com/",
    },
  ],
  // "premier": [
  //   {
  //     "name": "Steuben County Community Foundation",
  //     "image": "https://i2.lensdump.com/i/gdaq4F.jpg"
  //   },
  //   {
  //     "name": "The James Foundation",
  //     "image": "https://i2.lensdump.com/i/t4Gnhb.jpg"
  //   },
  // ],
  vip: [
    {
      name: "Stoy Farms",
      image: "https://b.l3n.co/i/loNhJC.png",
    },
    {
      name: "Billman Propane",
      image: "https://c.l3n.co/i/loNa2v.jpeg",
    },
    {
      name: "Bowen Health Clinic",
      image: "https://b.l3n.co/i/loN5o2.jpeg",
    },
    //     https://lensdump.com/i/loN5o2
    // https://lensdump.com/i/loNa2v
    // https://lensdump.com/i/loNhJC
  ],
  // "illumination": [
  //   {
  //     "name": "Miller Poultry",
  //     "image": "https://i2.lensdump.com/i/tHXmOF.png"
  //   },
  // ],
  corporate: [
    {
      name: "ReMax",
      image: "https://i2.lensdump.com/i/gdaJ6T.jpg",
    },
    {
      name: "Michigan Cat",
      image: "https://i1.lensdump.com/i/gdakL5.jpg",
    },
    {
      name: "Findlay Convention and Visitors Bureau",
      image: "https://i.lensdump.com/i/gdaEdC.png",
    },
    {
      name: "Sullair",
      image: "https://i3.lensdump.com/i/gdaQlb.png",
    },
    {
      name: "Cosmic Crisp",
      image: "https://a.l3n.co/i/ls0Lzi.jpeg",
    },
  ],
  balloon: [
    {
      name: "Wagler & Associates",
      image: "https://i2.lensdump.com/i/gda1P2.jpg",
    },
    {
      name: "Jimmy John's",
      image: "https://i2.lensdump.com/i/gdaIlm.jpg",
    },
    {
      name: "Farmers State Bank",
      image: "https://i3.lensdump.com/i/gdaWLK.jpg",
    },
    {
      name: "JICI",
      image: "https://i1.lensdump.com/i/gda0kP.jpg",
    },
    {
      name: "Croxton & Roe Insurance",
      image: "https://i1.lensdump.com/i/gda8i1.png",
    },
    {
      name: "Campbell & Fetter Bank",
      image: "https://i.lensdump.com/i/gdGhNH.jpg",
    },
    {
      name: "Denny Zent",
      image: "https://i3.lensdump.com/i/gdaTiv.png",
    },
    {
      name: "Fort Financial",
      image: "https://i.lensdump.com/i/gdaiaZ.jpg",
    },
    {
      name: "Best One Tire & Auto",
      image: "https://i3.lensdump.com/i/gdGwkQ.jpg",
    },
    {
      name: "Monument Pizza",
      image: "https://i2.lensdump.com/i/t4GHfC.jpg",
    },
    {
      name: "Jacob Insurance",
      image: "https://i3.lensdump.com/i/gdaZbi.jpg",
    },
    {
      name: "Dry Dock Marine",
      image: "https://b.l3n.co/i/loPTyA.jpeg",
    },
    {
      name: "Angola Area Chamber of Commerce",
      image: "https://i2.lensdump.com/i/gdGXGM.jpg",
    },
    {
      name: "Bills Professional Towing",
      image: "https://i.lensdump.com/i/gdGxHa.jpg",
    },
    {
      name: "Bon Appetit",
      image: "https://a.l3n.co/i/loPReq.jpeg",
    },
    {
      name: "Lakeland Electronics",
      image: "https://i.lensdump.com/i/t4G712.jpg",
    },
    {
      name: "Angola Chrysler Dodge Jeep Ram",
      image: "https://i.lensdump.com/i/C8wMUe.webp",
    },
    {
      name: "3 Rivers Credit Union",
      image: "https://i2.lensdump.com/i/C8wwci.png",
    },
    {
      name: "Midwest America Federal Credit Union",
      image: "https://c.l3n.co/i/ls0PjC.jpeg",
    },
    {
      name: "Hot Rod Golf Cart",
      image: "https://b.l3n.co/i/loP1JD.jpeg",
    },
  ],
  polegrab: [
    {
      name: "Tony Issa ReMax",
      image: "https://i.lensdump.com/i/gda4Mq.png",
    },
  ],
  archery: [
    {
      name: "Bowen Health Clinic",
      image: "https://b.l3n.co/i/loN5o2.jpeg",
    },
  ],
  kidsfunzone: [
    {
      name: "Angola FOP",
      // "image": "#"
    },
    {
      name: "Angola Dairy Queen",
      // "image": "#"
    },
    {
      name: "First Federal Savings Bank",
      // "image": "#"
    },
    {
      name: "Kona Ice",
      // "image": "#"
    },
    {
      name: "Fort Financial",
      // "image": "#"
    },
    {
      name: "Dekko Foundation",
      // "image": "#"
    },
    {
      name: "Angola Chamber of Commerce",
      // "image": ""
    },
    {
      name: "3 Rivers Credit Union",
    },
    {
      name: "McDonald's",
    },
    {
      name: "JICI",
      // "image": "#"
    },
  ],
  tshirt: [
    {
      name: "KPC Media",
      // "image": "#"
    },
    {
      name: "Ben's Pretzels",
      // "image": "#"
    },
    {
      name: "Hot Rod Golf Cart",
      // "image": "#"
    },
    {
      name: "Timbers Seafood & Steakhouse",
      // "image": "#"
    },
    {
      name: "First Federal Savings Bank",
      // "image": "#"
    },
    {
      name: "Castle Centurion Exterior",
      // "image": "https://i1.lensdump.com/i/gdaKhA.jpg"
    },
    {
      name: "Home Town Media",
      // "image": "#"
    },
    {
      name: "Bon Appetit",
      // "image": "#"
    },
    {
      name: "Complete Collision Service",
      // "image": "https://i2.lensdump.com/i/gda76M.jpg"
    },
    {
      name: "Covington Box",
      // "image": "#"
    },
    {
      name: "Angola Chrysler Dodge Jeep Ram",
      // "image": "https://i.lensdump.com/i/t4GYwF.jpg"
    },
    {
      name: "Angola Dairy Queen",
      // "image": ""
    },
    {
      name: "Intrepid Helicopter",
      // "image": ""
    },
    {
      name: "Purity Cylinder Gases",
    },
    {
      name: "Meijer",
      // "image": "#"
    },
    {
      name: "Jax Kar Wash",
      // image: "#",
    },
    {
      name: "Truland Equipment",
      // image: "#",
    },
    {
      name: "MSD of Steuben County",
    },
    {
      name: "Cricket Wireless",
    },
    {
      name: "Dellinger Orthodontics",
    },
    {
      name: "Bowen Health Clinic",
    },
  ],
  contributing: [
    "Angola Fire Department",
    "Steuben County EMS",
    "MSD of Steuben County",
    "Fairview Missionary Church",
    "Metz Fire Department",
    "Indiana State Police, FW District 22",
    "Angola One Stop",
    "Dominos",
    "REMC",
    "Miller Poultry",
  ],
};
