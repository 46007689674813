export const navLinks = [
    {
      title: 'Home',
      url: '/'
    },
    {
      title: 'Schedule',
      url: '/schedule',
    },
    {
      title: 'Pilots',
      url: '/pilots',
    },
    {
      title: 'Special Shapes',
      url: '/special_shapes',
    },
    {
      title: 'Sponsors',
      url: '/sponsors',
    },
    // {
    //   title: 'Events',
    //   url: '/events'
    // },
    {
      title: 'Partner',
      url: '/partner',
    },
    {
      title: 'Vendors',
      url: '/vendors',
    },
  ]