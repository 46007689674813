import React from 'react';
import './styles.css';

const FullWidthGallery = ({ images }) => {
    return (
        <div className="full-width-gallery-wrapper">
            {
                images.map(({ image, alt}, index) => {
                    return (
                        <figure key={index} className="full-width-gallery-photo">
                            <img src={image} alt={alt} ></img>
                        </figure>
                    );
                })
            }
        </div>
    );
};

export default FullWidthGallery;